import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.baseClassName),
    style: _normalizeStyle({ width: _ctx.width })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(`${_ctx.baseClassName}-left`)
    }, [
      _renderSlot(_ctx.$slots, "extra", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.extra), 1)
      ], true)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(`${_ctx.baseClassName}-right`)
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)
  ], 6))
}